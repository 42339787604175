/*
Theme Name: ReactJS With Redux Templat
Author: Bao Tuan Tran
Author URI: https://baholdings.vn
Description: ReactJS With Redux Template
Version: 0.1.0
Tags:

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Alignments
# Layouts
# Header
# Sidebar
# Footer
# Content
	## Pages
	## Posts and Comments
# Media
# Responsive
# Animations
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

@import 'variables';
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-pro/css/all.min.css';
@import '~@fortawesome/fontawesome-pro/css/sharp-solid.min.css';
@import '~@fortawesome/fontawesome-pro/css/sharp-light.min.css';

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Saira:wght@300;600;700&family=Manrope:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,800&family=Manrope:wght@400&display=swap');

:root {
	--app-padding-top: 4rem;
	--app-padding-bottom: 4rem;
}

body {
	font-family: var(--bs-font-sans-serif);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--bs-primary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
	word-wrap: normal;
	font-weight: 700;
	max-width: 100%;
}

p {
	max-width: 100%;
	
	a {
		font-weight: 600;
		color: inherit;

		&:hover, &:focus {
			color: var(--bs-primary);
		}
	}
}

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
a {
	text-decoration: none;
}

a, button {
	cursor: pointer;
	outline: none !important;
}

.btn {
	font-weight: 700;
}

a, button, .btn, .dropdown-item, .nav-link {
	i {
		margin-right: 8px;
	}
}

.btn-icon i {
	margin-right: 0 !important;
}

.btn-icon-right i {
	margin-right: 0;
	margin-left: 8px;
}

.btn-rounded {
	border-radius: 9999px;
}

.btn-link {
	padding: 0;
	text-decoration: none !important;

	&:focus {
		box-shadow: none;
	}
}

.btn-primary {
	color: #FFF !important;
	background: linear-gradient(rgb(13, 97, 242), rgb(47, 123, 254));
}

.btn-warning,
.btn-success,
.btn-danger {
	color: #FFF !important;
}

.btn-200 {
	min-width: 200px;
	max-width: 100%;
}

ul, ol {
	padding-inline-start: 17px;
}

.dropdown-menu {
	.dropdown-item {
		&:focus {
			background-color: var(--bs-primary);
		}
	}
}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
.form-group {
	&.is-invalid {
		border: 1px solid var(--bs-danger);
		border-radius: var(--bs-border-radius);
	}
}

.form-input-with-copy {
	.form-control {
		box-shadow: none !important;
	}
}

.form-image-select {
	position: relative;
	width: 100%;
	max-width: 100%;

	input {
		// width: calc(100% - 1.5rem);
		height: 1px;
		padding: 0;
		border: none !important;
		box-shadow: none !important;
		outline: none !important;
		margin: -1px auto 0;
	}

	.form-label {
		cursor: pointer;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 200px;
		text-align: center;
		background-color: var(--bs-gray-200);
		border-radius: var(--bs-border-radius);
		margin-bottom: 0;
		overflow: hidden;
	}

	.form-image-preview {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: var(--bs-gray-200);

		img {
			object-fit: contain;
		}
	}
}

/*--------------------------------------------------------------
# Navigation
	## Links
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Navigation
	## Menus
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Content
	## Pages
--------------------------------------------------------------*/
.page-header {
	position: relative;
	padding-top: 9rem;
	padding-bottom: var(--app-padding-bottom);
	color: var(--bs-white);
	background-color: var(--bs-primary);

	.sec-header {
		.sec-description {
			max-width: 100%;
		}
	}
}

/*--------------------------------------------------------------
# Content
	## Sections
--------------------------------------------------------------*/
.page-section {
	position: relative;
	padding-top: var(--app-padding-top);
	padding-bottom: var(--app-padding-bottom);
}

.sec-header {
	&:not(:last-child) {
		margin-bottom: 3rem;
	}

	.sec-title {
		font-size: 2.5rem;
		font-weight: 800;
		margin-bottom: .5rem;

		&:last-child {
			margin-bottom: 0;
		}

		@media (max-width: 767px) {
			font-size: 2rem;
		}
	}

	.sec-description {
		font-size: 1.125rem;
		margin: 0 auto;
		max-width: 720px;
	}
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@media (max-width: 767px) {
	.desktop {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.mobile {
		display: none !important;
	}
}

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@keyframes fadeIn {
	0%      { opacity: 0; }
	100%    { opacity: 1; }
}
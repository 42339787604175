@import 'assets/scss/variables.scss';

.page-home {
    color: #FFF;
}

.home-header {
    position: relative;
    padding-top: 10rem;

    .section-bg {
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .1;
    }

    .slogan-item {
        padding: 1.5rem;
        border: 2px solid rgba(255,255,255,.2);
        border-radius: 1.5rem;
        color: #FFF;
        background-color: rgba(255,255,255,.1);
        font-size: 1.5rem;
        text-transform: uppercase;

        p {
            margin: 0;
        }
    }
}

.about-section {
    .section-bg {
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .1;
    }

    .sec-header {
        .sec-title {
            margin-bottom: 2rem;
        }

        .sec-description {
            font-size: 1.25rem;
        }
    }

    .about-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
            margin: 0;
        }
    }

    .btn-lg {
        font-size: 1rem;
    }

    .about-text {
        position: relative;
        z-index: 2;
    }

    .about-img {
        position: relative;
        z-index: 1;
        
        @media (min-width: 768px) {
            transform: translateX(-10%);
        }
    }
}

.problem-section {
    color: #000;
    background-color: #FFF;
    padding-top: 2rem;

    .sec-header {
        .sec-title {
            margin-bottom: 1rem;
            color: var(--bs-primary);
        }
    }
}

.membershipCard-section {
    padding-bottom: 6rem;

    .sec-header {
        .sec-big-title {
            font-size: 4.5rem;
            font-weight: 700;
        }
    }

    .membercard-item {
        padding: 2.5rem 1.75rem;
        border-radius: 2rem;
        overflow: hidden;
        margin-top: 5rem;

        @media (max-width: 767px) {
            margin-top: 3rem;
        }

        &.market {
            color: #FFF;
            background-color: rgba(255,255,255,.1);
            border: 2px solid rgba(255,255,255,.2);
        }

        &.locamart {
            color: #000;
            background-color: #FFF;

            .price {
                b {
                    font-size: 2.25rem;

                    @media (max-width: 767px) {
                        font-size: 2rem;
                    }
                }
            }
        }

        h3 {
            text-align: center;
        }

        .price {
            display: inline-block;
            text-align: end;
            font-size: 1.75rem;
            margin: 1.5rem auto 2.5rem;

            @media (max-width: 767px) {
                font-size: 1.5rem;
            }
        }

        .membercard-list {
            list-style: none;

            li {
                position: relative;
                padding-left: 55px;

                &:not(:first-child) {
                    margin-top: 1.75rem;
                }

                &::before {
                    position: absolute;
                    left: 0;
                    top: calc(50% - 22px);
                    content: '';
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    width: 45px;
                    height: 45px;
                }
            }

            &.market {
                li {
                    &::before {
                        background-image: url('../../../assets/images/section-4/decline.png');
                    }
                }
            }

            &.locamart {
                li {
                    &::before {
                        background-image: url('../../../assets/images/section-4/check.png');
                    }
                }
            }
        }

        .footer-img {
            margin: 3rem -1.75rem -2.5rem;
        }
    }
}

.affiliate-section {
    color: #000;
    background-color: #FFF;

    .sec-header {
        .sec-big-title {
            font-size: 3rem;
            font-weight: 700;
        }
        
        .sec-title {
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 1rem;
        }
    }

    .affiliate-header-wrapper {
        border-radius: 1.5rem;
        box-shadow: rgb(84, 84, 84) 0px 22px 64px -35px;
        padding-top: 2rem;
        overflow: hidden;
        margin-bottom: 5rem;
    }

    .affiliate-header-img {
        position: relative;
        z-index: 0;
        
        @media (min-width: 768px) {
            margin-top: -9rem;
        }
    }
}

.register-section {
    color: #000;
    background-color: #e8f7fd;
    background-image: url('../../../assets/images/section-6/bg-img.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 50%;
    padding-bottom: 8rem;
    
    .register-form {
        color: #000;

        .form-floating {
            label, .form-control, .form-select {
                color: var(--bs-primary);

                &::after {
                    background-color: transparent;
                }
            }

            .form-control, .form-select {
                font-weight: 600;
                background-color: #FFF;
                box-shadow: 2px 2px 8px rgba(0,0,0,.015);
            }
        }
    }
}

.branches-section {
	.section-bg {
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .1;
    }

    .branch-slider-item-wrapper {
        height: 100%;
    }
    .branch-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 1.5rem;
        border-radius: .75rem;
        overflow: hidden;
        color: var(--bs-primary);
        background-color: #FFF;
        filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, .15));

        .item-icon {
            margin-bottom: 1rem;
        }

        .item-info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .item-name {
                margin-bottom: .25rem;
            }
            .item-address {
                color: var(--bs-gray-600);
                margin-bottom: 0;
            }
        }
    }
}

#branchesModal {
    color: #000;

    .modal-body {
        padding-top: 3rem;
        background-color: #e8f7fd;
        background-image: url('../../../assets/images/section-6/bg-img.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 50%;
    }

    .branch-item {
        filter: drop-shadow(2px 2px 16px rgba(0, 0, 0, .05));
    }
}